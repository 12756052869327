import {
	Button,
	DoughButtonProps,
	Modal,
	pxToRem,
	ResponsiveFullScreenModal,
} from "@getbread/dough";
import { Box, Chip, Select } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import { stdSpacing } from "../../global.style";
import { LinkButton } from "./LinkButton";

/** Modals */
export const ModalSmall = styled(Modal)`
	width: ${pxToRem(300)};
	margin: auto;
` as typeof Modal;

export const ModalLarge = styled(ResponsiveFullScreenModal)`
	> .modal {
		@media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
			padding: var(--space-m);

			/** Since we set the padding for the modal to be smaller for mobile view, the negative margins for this divider must also be adjusted, or it causes a horizontal scroll */
			.MuiDivider-root {
				margin-right: calc(var(--space-m) * -1);
				margin-left: calc(var(--space-m) * -1);
			}
		}

		.modal-component-wrapper {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}

		.modal-content {
			.modal-title {
				font-weight: 500;
			}

			.icon-back.on-bottom {
				margin-top: 0;
			}
		}

		::after {
			display: flex;
		}

		.footer-links {
			margin-top: auto;
			padding-top: var(--space-xxl);
		}
	}

	&.hide-back-link {
		> .modal {
			.MuiButton-root.icon-back {
				display: none;
			}
		}
	}
` as typeof Modal;

export const WithBottomMargins = styled("div")<{ bottomMargin?: string }>`
	> * {
		margin-bottom: ${({ bottomMargin }) => bottomMargin || "var(--space-m)"};
	}
`;

export const PaymentThankYou = styled("div")`
	font-weight: 500;
	font-size: ${pxToRem(24)};
	line-height: ${pxToRem(28)};
	margin-bottom: var(--space-l);
`;

export const ReturnToHome: React.FC<DoughButtonProps> = (props) => (
	<Box paddingTop={stdSpacing.l} marginTop="auto">
		<Button data-testid="return-to-home-button" {...props} />
	</Box>
);

export const EmptyStateTitle = styled("div")`
	align-self: center;
	grid-column: 1/-1;
	margin-top: var(--space-xl);
	font-weight: bold;
	font-size: ${pxToRem(24)};
	padding-bottom: var(--space-s);
	width: 100%;
	text-align: center;
`;

export const SpanAllColumns = styled("div")`
	grid-column: 1/-1;
`;

export const SecondaryButton = styled(LinkButton)`
	&&& {
		font-weight: 500;
		line-height: ${pxToRem(20)};
		font-size: ${pxToRem(16)};
		text-decoration: none;
	}

	&:hover {
		background-color: initial;
	}
`;

export const SmallSecondaryButton = styled(SecondaryButton)`
	&&& {
		font-size: ${pxToRem(14)};
		font-weight: 400;
		text-decoration: underline;
	}
`;

export const StyledSelect = styled(Select)`
	.MuiSelect-root {
		text-align: left;

		> .MuiBox-root {
			height: ${pxToRem(24)};
		}
	}
`;

/** Account Page Styles */
export const AccountPageCardHeader = styled("h2")`
	font-size: ${pxToRem(18)};
	font-weight: 800;
	line-height: ${pxToRem(21)};
	margin-block-start: 0;
	margin-bottom: var(--space-m);
`;

export const AccountPageCardDetail = styled("div")`
	font-size: ${pxToRem(16)};
	display: flex;
	justify-content: space-between;
	line-height: var(--space-xxl);

	&:last-child {
		margin-bottom: 0;
		align-items: center;
	}

	&.overflow {
		align-items: center;

		> * {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			&:hover {
				overflow: visible;
				overflow-wrap: anywhere;
				white-space: normal;
			}
		}
	}
`;

/** Status Chip Styles */
export const StatusChip = styled(Chip)`
	font-size: ${pxToRem(10)};
	font-weight: 700;
	height: ${pxToRem(24)};
	position: absolute;
	top: var(--space-m);
	right: var(--space-m);

	&.SUCCESS {
		background-color: ${({ theme }) => alpha(theme.palette.secondary.main, 0.7)};
	}

	&.FAILED,
	&.CANCELLED {
		background-color: ${({ theme }) => alpha(theme.palette.danger.main, 0.7)};
	}

	&.PENDING {
		background-color: ${({ theme }) => alpha(theme.palette.colors.black!, 0.3)};
	}

	&.VIRTUAL-CARD {
		background-color: ${({ theme }) => theme.palette.colors.seaspray};
	}
`;
