/**
 * Payment Method constants
 */

import { DisclosureName } from "@getbread/api-client-disclosure-v2";
import { OccStatus } from "@getbread/api-client-servicing-v2";
import { pxToRem } from "@getbread/dough";
import { ValueOf } from "type-fest";

import { AutoPayEnrollProps } from "../components/AutoPay/AutoPayEnroll";
import { ManageAutoPayProps } from "../components/AutoPay/ManageAutoPay";
import { ReviewPaymentProps } from "../components/MakePayment/ReviewPayment/ReviewPayment";
import { SelectPaymentFlowProps } from "../components/MakePayment/SelectPaymentFlow/SelectPaymentFlow";

/**
 * Payment Provider constants
 */

export const paymentProviderTypes = {
	DEBIT_CARD: "DEBIT_CARD",
	CREDIT_CARD: "CREDIT_CARD",
	BANK_ACCOUNT: "BANK_ACCOUNT",
} as const;

export type PaymentProviderType = ValueOf<typeof paymentProviderTypes>;

// the areas where you can add a payment method via a payment provider
export const paymentProviderContexts = {
	PAYMENT: "PAYMENT", // includes the Make A Payment flow as well as AutoPay
	ACCOUNT: "ACCOUNT",
};

export const paymentProviderFailureTypes = {
	blockedBankAccount: "blockedBankAccount",
	genericError: "genericError",
	invalidBankAccount: "invalidBankAccount",
	invalidRoutingNumber: "invalidRoutingNumber",
	invalidAccountNumber: "invalidAccountNumber",
	invalidAccountType: "invalidAccountType",
	duplicateBankAccount: "duplicateBankAccount",
};

export const pendingOCCStatuses: OccStatus[] = [
	OccStatus.PENDING,
	OccStatus.REQUESTED,
	OccStatus.UNDER_REVIEW,
	OccStatus.PAYMENTS_RECEIVED,
	OccStatus.READY_TO_PROCESS,
];

/**
 * Design Constants
 */
export const STYLE_CONSTANTS = {
	grid: { maxWidth: "1440px" },
	appBarZIndex: 1100,
	nav: {
		headerHeight: pxToRem(80),
	},
};

/**
 * Types
 */
export const paymentFlows = {
	INSTALLMENT: "INSTALLMENT",
	OTHER: "OTHER",
	OUTSTANDING_BALANCE: "OUTSTANDING_BALANCE",
	PAYOFF: "PAYOFF",
	PRINCIPAL: "PRINCIPAL",
} as const;

export type PaymentFlow = keyof typeof paymentFlows;

export const ModalTypes = {
	AUTOPAY_ENROLL: "AUTOPAY_ENROLL",
	MANAGE_AUTOPAY: "MANAGE_AUTOPAY",
	MAKE_PAYMENT: "MAKE_PAYMENT",
	PAY_OFF: "PAY_OFF",
} as const;

export type ModalType = keyof typeof ModalTypes;

export type ModalTypePropsMap = {
	AUTOPAY_ENROLL: AutoPayEnrollProps;
	MANAGE_AUTOPAY: ManageAutoPayProps;
	MAKE_PAYMENT: SelectPaymentFlowProps;
	PAY_OFF: ReviewPaymentProps;
};

// cob is a special disclosure type used only by Member Portal.
// under the hood, when cob is used, DisclosurePage.tsx determines if
// cob-maintenance or cob-activation is actually used for the API call.
export const DisclosureViewTypes = { ...DisclosureName, cob: "cob" } as const;
export type DisclosureViewType = keyof typeof DisclosureViewTypes;

export const defaultPaymentProvider = "Manual";

/** Balance Transfer consts */
export const balanceTransferState = {
	CREATED: "CREATED",
	DELETED: "DELETED",
	SUBMITTED: "SUBMITTED",
	ACCEPTED: "ACCEPTED",
	PAID: "PAID",
	FAILED: "FAILED",
	REPLACED: "REPLACED",
	PENDING: "PENDING",
	SUCCESS: "SUCCESS",
	CANCELLED: "CANCELLED",
	TERMINATED: "TERMINATED",
} as const;

// Balance transfer states to be omitted from Loan Cards
export const BALANCE_TRANSFER_PENDING_OMIT = [
	balanceTransferState.DELETED,
	balanceTransferState.REPLACED,
] as const;
export const BALANCE_TRANSFER_OUTSTANDING_OMIT = [
	...BALANCE_TRANSFER_PENDING_OMIT,
	balanceTransferState.TERMINATED,
] as const;
export const BALANCE_TRANSFER_TERMINAL_STATES = [
	...BALANCE_TRANSFER_OUTSTANDING_OMIT,
	balanceTransferState.CANCELLED,
] as const;

/** Translation constants */
export const EMPTY_TRANSLATION = " ";

/* Virtual Card constants */
export const channel = {
	INSTORE: "INSTORE",
};

export const virtualCardStatusChip = {
	INSTORE: "IN-STORE PURCHASE",
	VIRTUALCARD: "VIRTUAL-CARD",
};

// Environment Name Mapping Constants
export const ENV: Record<string, string> = {
	"local": "-dev",
	"npp-dev-ads": "-dev",
	"npp-qa-ads": "-qa",
	"sp-pv-ads": "-pv",
	"pp-prod-ads": "",
};
